import { createSlice } from '@reduxjs/toolkit';
import { forEach } from 'lodash';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {

  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  conversationMessages: { messages: [] },
  activeConversationId: null,
  activeChatId: null,
  participants: [],
  recipients: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    // getContactsSuccess(state, action) {
    //   const contacts = action.payload;

    //   state.contacts.byId = objFromArray(contacts,'id');
    //   state.contacts.allIds = Object.keys(state.contacts.byId);
    // },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;
      state.conversations.byId = objFromArray(conversations,'id');
      state.conversations.allIds = Object.keys(state.conversations.byId);
      state.isLoading = false;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const {chatId, conversations, conversationKey} = action.payload;
      state.activeConversationId = conversationKey;
      state.activeChatId = chatId;
      if (conversations && conversationKey) {
        state.conversationMessages.messages =  conversations;
      } else {
        state.conversationMessages.messages = [];
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const newMessage = action.payload;
      if (newMessage?.isFreshConversation && (state.activeConversationId === newMessage?.sentBy) || state.activeConversationId === newMessage?.sentTo) {
        state.activeChatId = newMessage?.conversationId;
        delete newMessage.isFreshConversation
      }
      if(state.activeChatId === newMessage?.conversationId) {
        state.conversationMessages.messages.push(newMessage)
      }
    },

    // ON SEND MESSAGE Last message update
    onSendLastMessageUp(state, action) {
      const newMessage = action.payload;
      const {id, conversationId, message, messageType, senderProfile, createdAt} = newMessage;

      state.conversations.byId[conversationId].messages = [
        {id, conversationId, message, messageType, senderProfile, createdAt}
      ]

      if (state.activeChatId !== conversationId) {
        state.conversations.byId[conversationId].unreadCount += 1
      }
    },

    markConversationAsRead(state, action) {
      const id = action.payload;
      const conversation = state.conversations.byId[id];
      if (conversation?.unreadCount > 0) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    onUserStatusChange(state, action) {
      const resData = action.payload;
      forEach(state.conversations?.allIds, (itemId) => {
        const parts = []
        forEach(state.conversations?.byId[itemId]?.participants, (user) => {
          if(user?.userProfile?.id === resData?.id) {
            user.userProfile.isOnline = resData?.isOnline
          }
          parts.push(user)
        })
        state.conversations.byId[itemId].participants = parts
      })
      state.participants.map(item => {
        if (item?.id === resData?.id) {
          item.isOnline = resData?.isOnline
        }
        return item;
      });
    },

    onAddRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
      state.activeChatId = null;
      state.conversationMessages.messages = [];
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onSendMessage, onSendLastMessageUp, onUserStatusChange, onAddRecipients, markConversationAsRead, resetActiveConversation } = slice.actions;

// ----------------------------------------------------------------------

// export function getContacts() {
//   return async (dispatch) => {
//     try {
//       const response = await axios.get('/users?limit=5000000000');

//       const apiRes = (response && response.data) ? response.data : '';
//       if (apiRes && apiRes.status) {
//         dispatch(slice.actions.getContactsSuccess(apiRes.data));
//       }else{
//         dispatch(slice.actions.hasError((apiRes && apiRes.message)?apiRes.message : "Something went wrong!"));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getConversations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/chat/conversations');
      const apiRes = response.data || response;

      if (apiRes && apiRes?.success) {
        dispatch(slice.actions.getConversationsSuccess(apiRes?.data));
      }else{
        dispatch(slice.actions.hasError((apiRes && apiRes?.errorMsg) ? apiRes.errorMsg : "Something went wrong!"));
      }

      return apiRes;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {success:false, message:error?.message};
    }
  };

}

// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getConversationSuccess(''));
      const isGroup = conversationKey.includes('group_')
      const apiUrl  = (isGroup) ? `/chat/conversation/${conversationKey.replace("group_", "")}` : `/chat/conversations/${conversationKey}`;
      const response = await axios.get(apiUrl);
      const apiRes = response.data || response;
      if (apiRes && apiRes?.success) {
        dispatch(slice.actions.getConversationSuccess({chatId:apiRes?.data?.id, conversations:apiRes?.data?.messages, conversationKey:conversationKey.replace("group_", "")}));
        if (isGroup) {

          const participantsArr = []
          await apiRes?.data?.participants?.forEach((item)=>{
            participantsArr.push(item?.userProfile)
          })
          dispatch(slice.actions.getParticipantsSuccess(participantsArr));
        }
      }else{
        dispatch(slice.actions.hasError((apiRes && apiRes?.errorMsg)?apiRes?.errorMsg : "Something went wrong!"));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async (dispatch) => {
    try {
      const isGroup = conversationKey.includes('group_')
      const apiUrl  = (isGroup) ? `/group/${conversationKey.replace("group_", "")}` : `/users/${conversationKey}`;
      const response = await axios.get(apiUrl);
      const apiRes = response.data || response;
      if (apiRes && apiRes?.success) {
        dispatch(slice.actions.getParticipantsSuccess([apiRes?.data]));
      }else{
        dispatch(slice.actions.hasError((apiRes && apiRes?.errorMsg)?apiRes?.errorMsg : "Something went wrong!"));
      }
      return apiRes;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {success:false, message:error?.message};
    }
  };
}