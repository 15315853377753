// import { useState, useEffect } from 'react';
import { AccessControl } from 'accesscontrol';
// Hook
import useAuth from './useAuth';

// ----------------------------------------------------------------------

export default function useAccessControl(a,b) {
  const { user } = useAuth();
  let resVar = false;
  try {

    const userRole = user?.role
    const userId = user?.id
    if (userRole === 'SUPER_ADMIN') {
      return true;
    }

    const permissions = window.localStorage.getItem('permissions');
    const permissionsArr = JSON.parse(permissions)
    const ac = new AccessControl(permissionsArr);

    switch (a) {
      case 'createOwn':
        resVar = ac.can(userId).createOwn(b).granted
        break;
      case 'createAny':
        resVar = ac.can(userId).createAny(b).granted
        break;
      case 'readOwn':
        resVar = ac.can(userId).readOwn(b).granted
        break;
      case 'readAny':
        resVar = ac.can(userId).readAny(b).granted
        break;
      case 'editOwn':
        resVar = ac.can(userId).editOwn(b).granted
        break;
      case 'editAny':
        resVar = ac.can(userId).updateAny(b).granted
        break;
      case 'deleteOwn':
        resVar = ac.can(userId).deleteOwn(b).granted
        break;
      case 'deleteAny':
        resVar = ac.can(userId).deleteAny(b).granted
        break;
      default:
        resVar = false;
        break;
    }
  } catch (err) {
    // console.error(err);
  }
  return resVar;
}
