import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>


<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" version="1.1" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd"
viewBox="0 0 591.06 563.34">
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <polygon fill="#DB3327" points="16.97,169.22 101.61,14.27 494.74,11.86 574.26,166.61 567.75,199.17 528.69,234.32 524.78,549.42 63.85,548.12 69.05,239.53 33.9,222.6 15.67,192.66 "/>
  <path fill="white" d="M591.06 169.08c0,-2.54 -0.6,-4.95 -1.93,-7.13l-87.92 -154.59c-2.54,-4.47 -7.37,-7.37 -12.56,-7.37l-386.35 0c-5.19,0 -10.02,2.78 -12.56,7.37l-87.8 154.59c-1.21,2.17 -1.93,4.59 -1.93,7.13 0,34.66 20.89,64.37 50.72,77.54l0 302.23c0,7.97 6.52,14.49 14.49,14.49l80.07 -0c0.12,0 0.24,0 0.36,0l112.32 0c0.12,0 0.24,0 0.36,0l267.39 0c7.97,0 14.49,-6.52 14.49,-14.49l0 -300.42c0,-0.6 0,-1.09 -0.12,-1.57 29.95,-13.16 50.97,-42.99 50.97,-77.78zm-480.31 -139.97l369.44 0 68.72 120.89 -506.76 0 68.6 -120.89zm309.9 149.76c-4.59,26.09 -27.42,45.89 -54.83,45.89 -27.42,0 -50.24,-19.81 -54.83,-45.89l109.66 0zm-140.46 0c-4.59,26.09 -27.41,45.89 -54.83,45.89 -27.42,0 -50.24,-19.81 -54.95,-45.89l109.78 0zm-250.24 0l109.78 0c-4.59,26.09 -27.54,45.89 -54.95,45.89 -27.41,0.12 -50.24,-19.81 -54.83,-45.89zm49.76 355.62l0 -280.74c1.69,0.12 3.38,0.12 5.07,0.12 29.23,0 55.07,-14.86 70.29,-37.44 15.22,22.58 41.06,37.44 70.29,37.44 29.23,0 54.95,-14.86 70.29,-37.44 15.22,22.58 41.06,37.44 70.17,37.44 29.23,0 54.95,-14.86 70.17,-37.44 15.22,22.58 41.06,37.44 70.29,37.44 1.69,0 3.26,-0.12 4.95,-0.12l0 280.62 0 0c-143.82,0 -287.84,0.12 -431.52,0.12zm426.57 -309.6c-27.42,0 -50.24,-19.81 -54.83,-45.89l109.78 0c-4.71,25.97 -27.54,45.89 -54.95,45.89z"/>
  <g id="_1362231349504">
   <path fill="white" fillRule="nonzero" stroke="white" strokeWidth="26.04" strokeMiterlimit="5.75877" d="M292.07 475.83l-50.86 4.76 -32.71 -60.97 -26 2.43 5.94 63.47 -49.58 4.64 -17.26 -184.3 83.61 -7.83c15.98,-1.5 29.1,-0.07 39.32,4.29 10.22,4.36 17.94,10.79 23.2,19.34 5.26,8.55 8.41,18.41 9.45,29.54 0.88,9.43 0.4,17.58 -1.48,24.48 -1.88,6.9 -4.79,12.92 -8.73,18.05 -3.93,5.13 -8.6,9.72 -13.97,13.74l39.06 68.34zm-84.61 -93.39c6.37,-0.6 11.45,-3.6 15.19,-9.01 3.74,-5.41 5.31,-11.08 4.75,-17.03 -0.6,-6.37 -3.4,-11.64 -8.42,-15.76 -5.01,-4.12 -10.32,-5.89 -15.93,-5.37l-28.42 2.66 4.42 47.16 28.42 -2.66z"/>
   <g>
    <path fill="white" fillRule="nonzero" stroke="white" strokeWidth="26.04" strokeMiterlimit="5.75877" d="M376.06 485.58c-9.51,-0.97 -18.77,-3.12 -27.82,-6.45 -9.05,-3.33 -17.22,-7.81 -24.49,-13.45 -7.27,-5.64 -13.06,-12.4 -17.31,-20.26 -4.26,-7.86 -6.26,-16.77 -6.01,-26.78l50.43 5.17c0.49,4.43 1.94,8.31 4.34,11.64 2.4,3.33 5.69,6.03 9.85,8.13 4.16,2.1 9.04,3.46 14.64,4.03 5.09,0.52 9.99,0.51 14.63,-0.05 4.65,-0.55 8.52,-1.91 11.61,-4.04 3.09,-2.13 4.84,-5.34 5.28,-9.67 0.38,-3.74 -0.76,-6.77 -3.48,-9.11 -2.72,-2.34 -6.34,-4.3 -10.85,-5.87 -4.51,-1.58 -9.3,-3.05 -14.3,-4.43l-27.65 -7.72c-13.86,-3.74 -24.61,-10.63 -32.29,-20.64 -7.68,-10.01 -10.92,-22.82 -9.65,-38.48 1.1,-12.41 5.54,-22.68 13.28,-30.77 7.73,-8.09 17.59,-13.85 29.57,-17.3 11.98,-3.45 24.91,-4.44 38.83,-3.01 16.81,1.72 30.78,5.64 41.91,11.76 11.13,6.12 19.3,13.86 24.47,23.23 5.17,9.37 7.28,19.75 6.37,31.15l-49.16 -5.04c-0.02,-4.81 -1.07,-8.82 -3.15,-11.99 -2.08,-3.17 -5.04,-5.62 -8.94,-7.35 -3.9,-1.73 -8.71,-2.87 -14.4,-3.45 -4.76,-0.49 -8.75,-0.47 -12.02,0.1 -3.28,0.57 -5.94,1.45 -7.95,2.66 -2.01,1.21 -3.49,2.65 -4.47,4.26 -0.98,1.62 -1.58,3.27 -1.75,4.97 -0.43,4.16 0.55,7.65 2.92,10.47 2.37,2.82 7.43,5.35 15.18,7.6l27.78 7.74c12.92,3.64 22.88,8.22 29.86,13.78 6.98,5.56 11.94,11.56 14.85,17.95 2.91,6.39 4.5,12.6 4.83,18.64 0.32,6.04 0.25,11.35 -0.22,15.94 -1.07,10.44 -5.47,19.43 -13.23,27 -7.77,7.57 -17.86,13.14 -30.23,16.68 -12.37,3.54 -26.12,4.53 -41.23,2.98z"/>
    <path fill="#DB3327" fillRule="nonzero" d="M365.52 483.99c-9.34,-2.04 -18.29,-5.23 -26.91,-9.56 -8.62,-4.33 -16.22,-9.71 -22.81,-16.13 -6.59,-6.42 -11.57,-13.8 -14.91,-22.09 -3.34,-8.29 -4.32,-17.37 -2.94,-27.29l49.52 10.84c-0.01,4.45 0.98,8.47 2.99,12.06 2.01,3.59 4.97,6.64 8.86,9.19 3.9,2.56 8.59,4.46 14.09,5.66 5,1.1 9.86,1.64 14.54,1.61 4.68,-0.02 8.68,-0.94 11.99,-2.7 3.31,-1.76 5.41,-4.76 6.34,-9.01 0.8,-3.67 0.01,-6.81 -2.43,-9.44 -2.44,-2.63 -5.81,-4.98 -10.12,-7.06 -4.31,-2.08 -8.89,-4.09 -13.71,-6.02l-26.6 -10.8c-13.35,-5.28 -23.24,-13.35 -29.74,-24.16 -6.5,-10.82 -8.26,-23.91 -5.24,-39.33 2.5,-12.21 8.07,-21.91 16.67,-29.07 8.6,-7.16 19.05,-11.77 31.34,-13.84 12.29,-2.07 25.25,-1.59 38.92,1.4 16.51,3.61 29.94,9.09 40.3,16.43 10.36,7.34 17.61,15.96 21.69,25.85 4.08,9.89 5,20.45 2.81,31.67l-48.27 -10.57c0.52,-4.78 -0.06,-8.88 -1.77,-12.27 -1.71,-3.39 -4.37,-6.16 -8.05,-8.32 -3.68,-2.16 -8.33,-3.83 -13.92,-5.06 -4.67,-1.02 -8.64,-1.45 -11.95,-1.26 -3.32,0.19 -6.07,0.77 -8.2,1.74 -2.13,0.97 -3.76,2.23 -4.92,3.73 -1.16,1.49 -1.94,3.07 -2.3,4.74 -0.89,4.09 -0.32,7.66 1.71,10.73 2.04,3.07 6.78,6.16 14.22,9.27l26.72 10.83c12.43,5.08 21.8,10.76 28.11,17.07 6.31,6.32 10.56,12.84 12.72,19.52 2.16,6.68 3.04,13.03 2.68,19.07 -0.36,6.04 -1.03,11.31 -2.02,15.81 -2.25,10.25 -7.63,18.68 -16.2,25.33 -8.57,6.64 -19.23,11.03 -31.93,13.15 -12.69,2.11 -26.46,1.54 -41.3,-1.71z"/>
   </g>
   <path fill="#DB3327" fillRule="nonzero" d="M286.34 485.79l-51.08 0 -26.88 -63.75 -26.12 0 0 63.75 -49.8 0 0 -185.11 83.98 0c16.05,0 28.97,2.65 38.75,7.94 9.77,5.29 16.85,12.42 21.29,21.42 4.44,9 6.66,19.12 6.66,30.3 0,9.47 -1.24,17.54 -3.76,24.24 -2.52,6.7 -5.97,12.42 -10.37,17.15 -4.4,4.74 -9.47,8.88 -15.19,12.37l32.52 71.69zm-75.53 -100.88c6.4,0 11.73,-2.52 15.96,-7.55 4.22,-5.04 6.32,-10.54 6.32,-16.51 0,-6.4 -2.3,-11.91 -6.91,-16.47 -4.61,-4.57 -9.73,-6.83 -15.36,-6.83l-28.55 0 0 47.37 28.55 0z"/>
  </g>
  <line fill="none" stroke="white" strokeWidth="28.65" strokeMiterlimit="22.9256" x1="296.77" y1="163.54" x2="296.77" y2= "19.01" />
  <line fill="none" stroke="white" strokeWidth="28.65" strokeMiterlimit="22.9256" x1="434.46" y1="163.54" x2="408.59" y2= "22.92" />
  <line fill="none" stroke="white" strokeWidth="28.65" strokeMiterlimit="22.9256" x1="154.69" y1="163.54" x2="180.56" y2= "22.92" />
 </g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}