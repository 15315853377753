import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import {toArray, mapValues, find} from 'lodash';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Link, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useAccessControl from '../../../hooks/accessControl';
// import useAuth from '../../../hooks/useAuth';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
// import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  // const { user } = useAuth();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  // const arrVal = (user?.roles?.length) ? mapValues(user?.roles, 'key') : [];
  // const userCRole = toArray(arrVal)

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  navConfig.map((item) => {
    const delMain = [];
    item?.items.map((subItem, ind) => {
    const delSub = [];
      if(subItem?.isAccess) {
        const accVar = subItem?.isAccess.split(',');
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if(!useAccessControl(accVar[0], accVar[1])){
          delMain.push(ind)
        }
      }
      if(subItem?.children) {
        subItem?.children.forEach((subSubItem, ind) => {
          if(subSubItem?.isAccess) {
            const accVar = subSubItem?.isAccess.split(',');
            // eslint-disable-next-line react-hooks/rules-of-hooks
            if(!useAccessControl(accVar[0], accVar[1])){
              delSub.push(ind)
            }
          }
        })
      }
      delSub.forEach((del) => {
        delete subItem?.children[del]
      })
      return subItem;
    })
    delMain.forEach((del) => {
      delete item?.items[del]
    })
    return item;
  })

  navConfig.map((item) => {
    const delChild = [];
    item?.items.map((subItem,ind) => {
      if(subItem?.children) {
        const allEmpty = Object.keys(subItem?.children).every(key => subItem?.children[key].length === 0)
        if(allEmpty) {
          delChild.push(ind)
        }
      }

      // if(subItem?.title === "settings" && userCRole.includes('STORE_ADMIN')) {
      //   if(!find(subItem?.children, { title: 'update store' })) {
      //     subItem?.children.push({ title: 'update store', path: PATH_DASHBOARD.settings.storeUpdate(user?.id) })
      //   }
      // }

      return subItem
    })

    delChild.forEach((del) => {
      delete item?.items[del]
    })

    return item;
  })

  // navConfig.map((item) => {
  //   item.items.map((subItem) => {
  //     if(subItem?.title === "settings") {
  //       subItem?.children.push({ title: 'logout', path: '#' })
  //     }
  //     return subItem;
  //   });
  //   return item;
  // });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo sx={{ width: 50, height: 50 }} />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!isCollapse && <NavbarDocs />} */}
      {!isCollapse &&
        <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}>
          <Typography variant="caption" component="p">
            © All rights reserved
            <br /> made by &nbsp;
            <Link target="_blank" href={process.env.REACT_APP_SITE_MAIN_URL} >{process.env.REACT_APP_SITE_MAIN_DOMAIN}</Link>
          </Typography>
        </Stack>
      }
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
