import axios from 'axios';
// config
import { JWT_RS_API } from '../config';

// ----------------------------------------------------------------------
let lng = JWT_RS_API.apiLocale;
if (typeof localStorage !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || 'en';
}

const axiosInstance = axios.create({
  baseURL: JWT_RS_API.apiUrl,
  timeout: 1000 * 60 * 2,
  headers: {
    locale: lng
  }
});

// if (JWT_RS_API.envType !== 'local') {
  axiosInstance.defaults.withCredentials = true;
// }

let isCallRef = true;
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {

    let refToken = '';
    if (typeof localStorage !== 'undefined') {
      refToken = localStorage.getItem('refreshToken') || '';
    }

    // eslint-disable-next-line prefer-destructuring
    const config = error.config;
    let isRefSend = false;
    let isReload  = true;
    if (config && config.url ){
      const errorUrl = config.url
      if (errorUrl.indexOf('/login') !== -1) {
        isCallRef = false;
      }
      if (errorUrl.indexOf('/token') !== -1) {
        isCallRef = false;
      }
      if (errorUrl.indexOf('/profile') !== -1) {
        isReload = false;
      }
    }

    if(error?.response && error?.response?.status === 403 && refToken !== '' && isCallRef === true && !isRefSend) {
      isRefSend = true;
      try {
        let resData;
        const res = await axiosInstance.post('/refresh-token', { token: refToken });
        if(res && res?.data && res?.data?.token && res?.data?.refreshToken) {
          localStorage.setItem('refreshToken', res?.data?.refreshToken);
          if(JWT_RS_API.envType === 'local'){
            localStorage.setItem('accessToken', res?.data?.token);
            config.headers.Authorization = `Bearer ${res.data.token}`;
          } else {
            localStorage.setItem('accessToken', 'yes');
          }
          resData = axiosInstance(config);
          if(JWT_RS_API.envType === 'local'){
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${res?.data?.token}`;
          }
          isRefSend = false;
        } else {
          resData = {errorCode:401, errorMsg:'Refresh token was expired, please sign in again!'};
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          delete axios.defaults.headers.common.Authorization;
          if (isReload) {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
        return resData
      } catch (err) {
        return {errorCode:401, errorMsg:'Refresh token was expired, please sign in again!'};
      }
    }

    let resError = ''; let resErrorCode = 500;
    if (error.response) {
      resErrorCode  = error.response.status || 500;
      resError      = error.response.data.message || 'Something went wrong!';
    } else if (error.request) {
      resError = 'Internal server error! Please try again later.';
    } else {
      resError = error.statusText || 'Something went wrong!';
    }
    // if(resErrorCode === 404) { resError = '404 Not Found'; }
    return {errorCode:resErrorCode, errorMsg:resError};
  }
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
export default axiosInstance;