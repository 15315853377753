import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// hooks
import useAccessControl from '../hooks/accessControl';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------
// const Loadable = (Component) => (props) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { pathname } = useLocation();
//   const isDashboard = pathname.includes('/dashboard');
//   return (
//     <Suspense fallback={<LoadingScreen isDashboard={false} sx={{
//         ...(!isDashboard && {
//           top: 0,
//           left: 0,
//           width: 1,
//           zIndex: 9999,
//           position: 'fixed'
//         })
//       }} />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

const Loadable = (Component) => (props) =>  (
  <Suspense fallback={<LoadingScreen isDashboard={false}
    sx={{
      top: '80px',
      position: 'relative',
    }}
  />}>
    <Component {...props} />
  </Suspense>
);


export default function Router() {

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          children: [
            {path: '', element: <GuestGuard><ResetPassword /></GuestGuard> },
            { path: ':token', element: <GuestGuard><ResetPassword /></GuestGuard> },
          ]
        },
        { path: '', element: <Navigate to="/404" replace /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <RoleBasedGuard isAccess><GeneralApp /></RoleBasedGuard> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','users')}><UserList /></RoleBasedGuard> },
            { path: 'new', element: <RoleBasedGuard isAccess={useAccessControl('createOwn','users')}><UserCreate /></RoleBasedGuard> },
            { path: ':id/edit', element: <RoleBasedGuard isAccess={useAccessControl('updateOwn','users')}><UserCreate /></RoleBasedGuard> }
          ],
        },
        { path: 'category', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','categories')}><CategoryList /></RoleBasedGuard> },
        { path: 'brand', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','brands')}><BrandList /></RoleBasedGuard>
        },
        { path: 'store',
          children: [
            { element: <Navigate to="/dashboard/store/list" replace />, index: true },
            { path:'list', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','store')}><StoreList /></RoleBasedGuard> },
            { path:'new', element: <RoleBasedGuard isAccess={useAccessControl('createOwn','store')}><StoreCreate /></RoleBasedGuard> },
            { path: ':id/edit', element: <RoleBasedGuard isAccess={useAccessControl('updateOwn','store')}><StoreCreate /></RoleBasedGuard> }
          ]
        },
        { path: 'media', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','files')}><Media /></RoleBasedGuard> },
        { path: 'location',
          children: [
            { path:'', element: <Navigate to="/dashboard/settings/country" replace /> },
            { path: 'country', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','country')}> <CountryList /> </RoleBasedGuard> },
            { path: 'state', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','state')}> <StateList /> </RoleBasedGuard> },
            { path: 'city', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','city')}> <CityList /> </RoleBasedGuard> },
          ]
        },
        { path: 'settings',
          children: [
            { path:'', element: <Navigate to="/dashboard/settings/account" replace /> },
            { path: 'account', element: <RoleBasedGuard isAccess><UserAccount /></RoleBasedGuard> },
            { path: 'profile',
              children: [
                { path: '', element: <RoleBasedGuard isAccess><Profile /></RoleBasedGuard> },
                { path: 'account', element: <RoleBasedGuard isAccess><ProfileAccount /></RoleBasedGuard> },
                { path: 'bank-details', element: <RoleBasedGuard isAccess><BankDetails /></RoleBasedGuard> },
                { path: 'business-details', element: <RoleBasedGuard isAccess><BusinessDetails /></RoleBasedGuard> },
                { path: 'calendar-manage', element: <RoleBasedGuard isAccess><CalendarManage /></RoleBasedGuard> },
              ]
            },
            { path: 'permission', element: <RoleBasedGuard isAccess={useAccessControl('readOwn','permission')}><GroupPermission /></RoleBasedGuard> },
          ]
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace={false} />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// DASHBOARD
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// Category And Brand
const BrandList = Loadable(lazy(() => import('../pages/dashboard/BrandList')));
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));

// Store
const StoreList = Loadable(lazy(() => import('../pages/dashboard/StoreList')));
const StoreCreate = Loadable(lazy(() => import('../pages/dashboard/StoreCreate')));

// Media
const Media = Loadable(lazy(() => import('../pages/dashboard/Media')));

// Locations
const CountryList = Loadable(lazy(() => import('../pages/dashboard/location/CountryList')));
const StateList = Loadable(lazy(() => import('../pages/dashboard/location/StateList')));
const CityList = Loadable(lazy(() => import('../pages/dashboard/location/CityList')));

// SETTINGS
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/profile/Profile')));
const ProfileAccount = Loadable(lazy(() => import('../pages/dashboard/profile/ProfileAccount')));
const BankDetails = Loadable(lazy(() => import('../pages/dashboard/profile/BankDetails')));
const BusinessDetails = Loadable(lazy(() => import('../pages/dashboard/profile/BusinessDetails')));
const CalendarManage = Loadable(lazy(() => import('../pages/dashboard/profile/CalendarManage')));
const GroupPermission = Loadable(lazy(() => import('../pages/dashboard/GroupPermission')));

// None =================================================

// MAIN
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));