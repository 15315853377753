// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, ''),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  category: path(ROOTS_DASHBOARD, '/category'),
  brand: path(ROOTS_DASHBOARD, '/brand'),
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    list: path(ROOTS_DASHBOARD, '/store/list'),
    new: path(ROOTS_DASHBOARD, '/store/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/store/${id}/edit`)
  },
  location: {
    root: path(ROOTS_DASHBOARD, '/location'),
    country: path(ROOTS_DASHBOARD, '/location/country'),
    state: path(ROOTS_DASHBOARD, '/location/state'),
    city: path(ROOTS_DASHBOARD, '/location/city'),
  },

  // None =================================================================
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
  },
  organization: {
    root: path(ROOTS_DASHBOARD, '/organization'),
    new: path(ROOTS_DASHBOARD, '/organization/new'),
    list: path(ROOTS_DASHBOARD, '/organization/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/organization/${id}/edit`),
    manage: path(ROOTS_DASHBOARD, '/organization/manage'),
  },
  inventory: path(ROOTS_DASHBOARD, '/inventory'),
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),

    review: path(ROOTS_DASHBOARD, '/product/review'),
    category: path(ROOTS_DASHBOARD, '/product/category'),
    strain: path(ROOTS_DASHBOARD, '/product/strain'),
  },
  storeMeshManage: path(ROOTS_DASHBOARD, '/manage-mesh'),
  inquiryList: path(ROOTS_DASHBOARD, '/inquiry-list'),
  catalog: {
    root: path(ROOTS_DASHBOARD, '/catalog'),
    list: path(ROOTS_DASHBOARD, '/catalog/list'),
    match: path(ROOTS_DASHBOARD, '/catalog/match'),
  },
  media: path(ROOTS_DASHBOARD, '/media'),
  orders: path(ROOTS_DASHBOARD, '/orders'),
  reminder: {
    root: path(ROOTS_DASHBOARD, '/reminder/cart-list'),
    cart: path(ROOTS_DASHBOARD, '/reminder/cart-list'),
    favorite: path(ROOTS_DASHBOARD, '/reminder/favorite'),
    wishlist: path(ROOTS_DASHBOARD, '/reminder/wishlist'),
    recentlyViewed: path(ROOTS_DASHBOARD, '/reminder/recently-viewed'),
    notification: path(ROOTS_DASHBOARD, '/reminder/notification')
  },
  newsletter: {
    root: path(ROOTS_DASHBOARD, '/newsletter'),
    registerUsers: path(ROOTS_DASHBOARD, '/newsletter/register-users'),
    contacts: path(ROOTS_DASHBOARD, '/newsletter/contacts'),
    smsAnalytics: path(ROOTS_DASHBOARD, '/newsletter/sms-analytics'),
    viewSMSData: (id) => path(ROOTS_DASHBOARD, `/newsletter/sms-analytics/${id}`),
    emailAnalytics: path(ROOTS_DASHBOARD, '/newsletter/email-analytics'),
    viewEmailData: (id) => path(ROOTS_DASHBOARD, `/newsletter/email-analytics/${id}`),
  },
  appRating: path(ROOTS_DASHBOARD, '/app-rating'),
  support: path(ROOTS_DASHBOARD, '/support'),
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    account: path(ROOTS_DASHBOARD, '/settings/account'),
    profile: {
      root: path(ROOTS_DASHBOARD, '/settings/profile'),
      account: path(ROOTS_DASHBOARD, '/settings/profile/account'),
      bankDetails: path(ROOTS_DASHBOARD, '/settings/profile/bank-details'),
      businessDetails: path(ROOTS_DASHBOARD, '/settings/profile/business-details'),
      calendarManage: path(ROOTS_DASHBOARD, '/settings/profile/calendar-manage'),
    },
    permission: path(ROOTS_DASHBOARD, '/settings/permission'),
    sliderHero: path(ROOTS_DASHBOARD, '/settings/slider-list'),
    faqs: path(ROOTS_DASHBOARD, '/settings/faqs'),
    otherPages: path(ROOTS_DASHBOARD, '/settings/other-pages'),
    storeUpdate: (id) => path(ROOTS_DASHBOARD, `/settings/store/${id}/edit`),
  }
};