import PropTypes from 'prop-types';
import { useState } from 'react';
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions} from '@mui/material';

const dialogContentStyle = { marginTop: '15px', paddingTop: '20px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' };

DeleteConfirmDialog.propTypes = {
  isDCOpen: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.any,
  onClose: PropTypes.func,
  onDeleteAgree: PropTypes.func
};

export default function DeleteConfirmDialog({isDCOpen, title, content, onClose, onDeleteAgree}) {
  const [open, setOpen] = useState(isDCOpen);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      scroll={'paper'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent style={dialogContentStyle} dividers>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}> Cancel </Button>
        <Button color="error" variant="contained" onClick={()=> onDeleteAgree() }> Delete </Button>
      </DialogActions>
    </Dialog>
  )
}