import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { m, AnimatePresence } from 'framer-motion';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
//
import Iconify from '../Iconify';
import { varFade } from '../animate';
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadMultiMedia.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onUploadAll: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object
};

export default function UploadMultiMedia({ onUploadAll, error, showPreview = false, files, onRemove, onRemoveAll, sx, ...other }) {
  const hasFile = files.length > 0;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          py:2,
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'transparent',
            border: 'none',
          })
        }}
        style={{border: 'none', borderRadius:'0'}}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 140 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            Drop or Select file
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Drop files here or click&nbsp;
            <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
              browse
            </Typography>
            &nbsp;thorough your machine
          </Typography>

          <Typography variant="caption" sx={{m: 0, color: 'text.secondary'}} >
            Allowed *.jpg, *.png, *.heic, *.avif, *jfif (max files of 12)
          </Typography>
        </Box>
      </DropZoneStyle>

      {(hasFile || fileRejections.length > 0) ?
        <Box sx={{ py: 2, px: 3, m:0 }}>
          {fileRejections.length > 0 && <ShowRejectionItems />}

          <List disablePadding sx={{ ...(hasFile && { m: 0 }) }}>
            <AnimatePresence>
              {files.map((file) => {
                const { name, size, preview } = file;
                const key = isString(file) ? file : name;

                if (showPreview) {
                  return (
                    <ListItem
                      key={key}
                      component={m.div}
                      {...varFade().inRight}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: 80,
                        height: 80,
                        borderRadius: 1.5,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex'
                      }}
                    >
                      <Paper
                        variant="outlined"
                        component="img"
                        src={isString(file) ? file : preview}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                      />
                      <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                        <IconButton
                          size="small"
                          onClick={() => onRemove(file)}
                          sx={{
                            p: '2px',
                            color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                            }
                          }}
                        >
                          <Iconify icon={'eva:close-fill'} />
                        </IconButton>
                      </Box>
                    </ListItem>
                  );
                }

                return (
                  <ListItem
                    key={key}
                    component={m.div}
                    {...varFade().inRight}
                    sx={{
                      my: 1,
                      py: 0.75,
                      px: 2,
                      borderRadius: 1,
                      border: (theme) => `solid 1px ${theme.palette.divider}`,
                      bgcolor: 'background.paper'
                    }}
                  >
                    <ListItemIcon>
                      <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={isString(file) ? file : name}
                      secondary={isString(file) ? '' : fData(size)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      secondaryTypographyProps={{ variant: 'caption' }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                        <Iconify icon={'eva:close-fill'} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </AnimatePresence>
          </List>

          {hasFile && (
            <Stack direction="row" sx={{ mt: -1 }} justifyContent="flex-end">
              <Button onClick={onRemoveAll} sx={{ mr: 1.5 }}>
                Remove all
              </Button>
              <Button onClick={onUploadAll} variant="contained">Upload files</Button>
            </Stack>
          )}
        </Box> : ''
      }
    </Box>
  );
}
