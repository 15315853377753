import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { toArray, mapValues } from 'lodash';
// @mui
import {
  Box,
  Stack,
  Card,
  Grid,
  LinearProgress,
  TextField,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Tooltip,
  Typography, CardContent, Skeleton, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import Image from '../../components/Image';
import TextMaxLine from '../../components/TextMaxLine';
import UploadMultiMedia from '../../components/upload/UploadMultiMedia';
import Iconify from '../../components/Iconify';
import DeleteConfirmDialog from '../../components/DeleteConfirmDialog';
import LightboxModal from '../../components/LightboxModal';
import EditMediaFrom from './EditMediaFrom';
// utils
import { fDateTime } from '../../utils/formatTime';
import axios from '../../utils/axios';
// Hooks
import { getImagePathBySize } from '../../utils/getImagePath';
// Api
import { getMediaImages, deleteMedia } from '../../apis/mediaApi';

// ----------------------------------------------------------------------

const rowsPerPage = 36;
const SkeletonLoad = (
  <>
    {[...Array(rowsPerPage)].map((_, index) => (
      <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '95%', borderRadius: 2 }} />
        {/* <Skeleton width="100%" height={210} variant="rectangular" sx={{ borderRadius: 2 }} /> */}
        <Box sx={{ mt: 0, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="100%" sx={{ paddingTop: '20%' }}/>
          </Box>
        </Box>
      </Grid>
    ))}
  </>
);

let timeout = null;

MediaList.propTypes = {
  selectedMedia: PropTypes.any,
  isMediaSelect: PropTypes.bool,
  isPrivateSec: PropTypes.any,
};
export default function MediaList({selectedMedia, isMediaSelect, isPrivateSec = false}) {

  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingRecd, setIsLoadingRecd] = useState(true);
  const [mediaFilesList, setMediaFilesList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [orderBy, setOrderBy] = useState('desc');
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [progressData, setProgressData] = useState(0);
  const [files, setFiles] = useState([]);
  const [onMediaSelected, setOnMediaSelected] = useState([]);
  const [isPrivate, setIsPrivate] = useState(isPrivateSec);

  // Delete
  const [isDeleteCofOpen, setIsDeleteCofOpen] = useState(false);
  const [deleteDialogRec, setDeleteDialogRec] = useState();
  const [isLoadingActionApi, setIsLoadingActionApi] = useState(false);

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageArr, setSelectedImageArr] = useState([]);

  const [openEditMedia, setOpenEditMedia] = useState(false);
  const [editMediaData, setEditMediaData] = useState();

  useEffect(() => {
    let active = true;
    setIsLoadingRecd(true);
    const fetchData = async () => {
      // get the data from the api
      setMediaFilesList([])
      const asdDesc = `&sortBy=createdAt&sortOrder=${orderBy}`;
      const search = (searchByName) ? `&search=${searchByName}` : '';
      const queryFilt = (isPrivate) ? '&filter[private]=true' : '';
      const querySrt = `?page=${page}&limit=${rowsPerPage}${queryFilt}${search}${asdDesc}`;
      try {
        const apiRes = await getMediaImages(querySrt);
        // set state with the result
        if(apiRes && apiRes.success) {
          const mListRes = apiRes.data;
          setMediaFilesList(mListRes.rows);
          const numOfPage = mListRes.pagination.count < rowsPerPage ? 1 : Math.ceil(mListRes.pagination.count / rowsPerPage);
          setTotalPage(numOfPage);
        } else {
          setMediaFilesList([]);
          enqueueSnackbar(apiRes.errorMsg || 'Something went wrong!', { variant: 'error' });
        }
        setIsLoadingRecd(false);
      } catch (error) {
        setMediaFilesList([]);
        enqueueSnackbar(error || 'Something went wrong!', { variant: 'error' });
      }
    }
    if (active) { fetchData() }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, isPrivate, searchByName]);

  const listData = mediaFilesList;
  const isNotFound = listData.length === 0;

  // Meida
  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFiles]
  );

  const onUploadHandle = async () => {
    if (!isApiLoading) {
      setIsApiLoading(true);
      try {
        const formData = new FormData()
        files.forEach( file => { formData.append("files", file) } );
        formData.append('platform', 'browser');
        let endPoint = '/files'
        if (isPrivate) {
          endPoint = '/files-private'
        }
        const response = await axios.post(`${endPoint}`, formData, { onUploadProgress: (progressEvent) => {
            const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10)
            setProgressData(progress)
          }},
        )

        const apiRes = response?.data || response;
        if(apiRes && apiRes?.success) {
          if (apiRes.data){
            setMediaFilesList(prevState => ([...apiRes.data, ...prevState]))
          }
          setFiles([])
          setProgressData(0)
          enqueueSnackbar('Media uploaded success')
        } else {
          enqueueSnackbar(apiRes.errorMsg || 'Something went wrong!', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error || 'Something went wrong!', { variant: 'error' });
      }
      setIsApiLoading(false);
    } else {
      enqueueSnackbar('Please wait for the upload to finish', { variant: 'error' });
    }
  }

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleMediaSelected = (id) => {
    if(isMediaSelect){
      setOnMediaSelected(onMediaSelected.includes(id) ? [] : [id]);
      selectedMedia(onMediaSelected.includes(id) ? [] : listData.filter(item => item.id === id))
    }
  }

  const selectStyle = (isMediaSelect) ? { cursor: 'pointer' } : { cursor: 'auto' };

  const handleOnChangeOrder = (event, ord) => {
    if (ord !== null) { setOrderBy(ord); setPage(1); }
  }
  const handleOnChangeType = (event, type) => {
    if (type !== null) { setIsPrivate(type); setPage(1); }
  }
  const handleOnFilterName = (filterName) => {
    setFilterName(filterName);
    clearTimeout(timeout);
    timeout = setTimeout(()=>{
      setSearchByName(filterName);
    }, 500)
    setPage(1);
  }

  const recordActionFunc = async (id) => {
    if(!isLoadingActionApi){
      setIsLoadingActionApi(true)
      const resMsg = {msg:'Something went wrong!', status:'error'};
      try {
        const apiRes = await deleteMedia(id);
        if (apiRes && apiRes.success) {
          resMsg.status = 'success';
          resMsg.msg    = apiRes?.message || 'Record deleted successfully!';
          setMediaFilesList(mediaFilesList.filter(item => item.id !== id));
          if (isMediaSelect) {
            selectedMedia([])
          }
          setOnMediaSelected([]);
        } else {
          resMsg.msg = apiRes.errorMsg || 'Something went wrong!';
        }
      } catch (error) {
        resMsg.msg = error.message || 'Something went wrong!';
        console.log(error)
      }
      setIsLoadingActionApi(false)
      enqueueSnackbar(resMsg.msg, { variant: resMsg.status });
    }
  }

  const handlerDeleteAgree = () => {
    recordActionFunc(deleteDialogRec);
    setIsDeleteCofOpen(false);
    setDeleteDialogRec();
  }

  const handleDeleteRow = (id) => {
    setDeleteDialogRec(id);
    setIsDeleteCofOpen(true);
  };

  const handleEditRow = (id) => {
    setOpenEditMedia(true)
    setEditMediaData(id)
  };

  const handleMediaImageUpdated = (data) => {
    if (data?.id) {
      const newData = mediaFilesList.map(item => {
        if (item.id === data?.id) { return data; }
        return item;
      });
      setMediaFilesList(newData);
    }

    setOpenEditMedia(true)
    setEditMediaData()
  }

  const handleOpenLightbox = (index) => {
    if (!isMediaSelect) {
      setOpenLightbox(true);
      setSelectedImage(index);
    }
  };

  useEffect(() => {
    if (listData.length > 0) {
      setSelectedImageArr(toArray(mapValues(listData, 'path')));
    }
  } , [listData])

  return (
    <>
      {/* maxWidth:'1060px', */}
      <Stack sx={{ mb:4, mx:'auto', pb:0, borderradius: '8px', border:"1px dashed #e8e8e8" }} spacing={0}>
        <CardContent style={{padding: '0px'}} sx={{px:0, py:0}}>
          <UploadMultiMedia
            accept="image/jpg, image/jpeg, image/png, image/heic, image/heif, image/avif, image/jfif"
            // accept="image/*, image/heic"
            maxFiles={12}
            showPreview
            files={files}
            onDrop={handleDropMultiFile}
            onUploadAll={onUploadHandle}
            onRemove={handleRemove}
            onRemoveAll={() => setFiles([]) }
          />
        </CardContent>
        {(isApiLoading && progressData) ? <Box sx={{ px:2, pb:1.5, width: '100%' }}> <LinearProgressWithLabel value={progressData} /> </Box> : ''}
      </Stack>

      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} justifyContent="space-between" sx={{ mb: 4 }} >
        <TextField
          sx={{ width: '350px' }}
          value={filterName}
          onChange={(event) => handleOnFilterName(event.target.value)}
          placeholder="Search image by name..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />

        <Stack direction="row" spacing={2}>
          <ToggleButtonGroup color="primary" value={isPrivate} exclusive size="medium" onChange={(handleOnChangeType)} aria-label="text alignment">
            <ToggleButton value="" disabled>Type:&nbsp;</ToggleButton>
            <ToggleButton disabled={(isMediaSelect && isPrivate)} value={false}> Public</ToggleButton>
            <ToggleButton disabled={(isMediaSelect && !isPrivate)} value> Private </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup color="primary" value={orderBy} exclusive size="medium" onChange={(handleOnChangeOrder)} aria-label="text alignment">
            <ToggleButton value="" disabled>Sort By:&nbsp;</ToggleButton>
            <ToggleButton value="desc" aria-label="left aligned"> Newest </ToggleButton>
            <ToggleButton value="asc" aria-label="centered"> Oldest </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>

      <Grid container spacing={3} >
        {!isLoadingRecd && listData.map((row, index) => {
          const { id, name, path, sizes, createdAt } = row;
          let imagePath = '';
          if (isPrivate) {
            imagePath = path;
          } else {
            imagePath = getImagePathBySize([{id,path,sizes}], 's300') || '/images/img_placeholder.svg'
          }

          if (onMediaSelected.includes(id)) {
            return (
              <Grid sx={selectStyle} key={id} item xs={6} sm={4} md={3} lg={2}>
               <Card sx={{borderWidth: 2,borderStyle: 'solid',borderColor: 'primary.main'}} onClick={()=>handleMediaSelected(id)}>
                  <Image alt="cover" src={imagePath} ratio="4/3" />
                  <CardContent sx={{ p:1.2, pb:2 }}>
                    <Typography gutterBottom variant="caption" component="div" sx={{ color: 'text.disabled' }}>{createdAt && fDateTime(createdAt)}</Typography>
                    <TextMaxLine variant={'subtitle2'} line={2} persistent> {name}</TextMaxLine>
                  </CardContent>
               </Card>
             </Grid>
           )
          }

          return (
             <Grid key={id} item xs={6} sm={4} md={3} lg={2}>
              <Card style={selectStyle} sx={{borderWidth: 2,borderStyle: 'solid',borderColor: 'transparent' }} onClick={()=>handleMediaSelected(id)}>
                <Image sx={{ cursor: 'pointer' }} alt="cover" src={imagePath} ratio="4/3" onClick={() => handleOpenLightbox(index)} />
                <CardContent sx={{ p:1.2 }} style={{paddingBottom: '10px'}}>
                  <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="caption" component="div" sx={{ color: 'text.disabled' }}>{createdAt && fDateTime(createdAt)}</Typography>
                    <Stack spacing={0.5} direction="row">
                      <Tooltip title="Edit">
                        <IconButton size="small" onClick={()=> handleEditRow(id)}>
                          <Iconify sx={{width: 20, height: 20 }} icon={'akar-icons:edit'} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton sx={{p:0.4}} onClick={()=> handleDeleteRow(id)}>
                          <Iconify icon={'eva:trash-2-outline'} sx={{ width: 20, height: 20, color: 'error.disabled', }} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  <TextMaxLine variant={'subtitle2'} line={2} persistent> {name}</TextMaxLine>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
        {isLoadingRecd && SkeletonLoad}
      </Grid>
      {(isNotFound && !isLoadingRecd) ? <Typography variant="h5" sx={{textAlign : 'center', mt:4}} gutterBottom component="div">No record found</Typography> : '' }

      <Stack spacing={2} sx={{mt:4, mb:5}} direction="row" justifyContent="center" alignItems="flex-end">
        <Pagination
          shape="rounded"
          variant="outlined"
          color="primary"
          count={totalPage}
          page={page}
          onChange={(e,np) => setPage(np)}
        />
      </Stack>


      {openEditMedia &&
        <EditMediaFrom
          open={openEditMedia}
          imageId={editMediaData}
          onMediaImageUpdated={handleMediaImageUpdated}
          onClose={()=> {
            setOpenEditMedia(false)
            setEditMediaData()
          }}
        />
      }

      {isDeleteCofOpen &&
        <DeleteConfirmDialog
          title="Delete Confirm"
          content={
              <span>If you delete this image, your customers will no longer see this image you associated with your products. <br/>
              Are you sure you want to delete this image?</span>
            }
          isDCOpen={isDeleteCofOpen}
          onClose={ () => {
              setIsDeleteCofOpen(false);
              setDeleteDialogRec();
            }
          }
          onDeleteAgree={handlerDeleteAgree}
        /> }

      <LightboxModal
        images={selectedImageArr}
        mainSrc={selectedImageArr[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}

function LinearProgressWithLabel(props) {
  // eslint-disable-next-line react/prop-types
  const { value } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}