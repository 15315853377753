import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import useAuth from './hooks/useAuth';
import { useDispatch } from './redux/store';
// import { PATH_DASHBOARD } from './routes/paths';
import { onReceiveNot, onNotifStatusChange, markNotiAsRead, resetNotifications, getNotifications } from './redux/slices/notification';
import { onUserStatusChange, markConversationAsRead } from './redux/slices/chat';
// ----------------------------------------------------------------------

export default function App() {
  const { isAuthenticated, user, socket } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const [playSoundRecieve] = useSound('/sound/msgRecieve.wav' );

  useEffect(() => {
    let active = true

    if (location?.pathname?.includes('/chat')) {
      dispatch(resetNotifications('SMS'))
    }

    if(socket && !location?.pathname?.includes('/chat')){
      socket.on("one to one", (resOn) => {
        if(resOn?.sentBy && active && user?.id !== resOn?.senderProfile?.id) {
          playSoundRecieve()
          dispatch(onReceiveNot(resOn))
        }
      });
    }

    if(socket){
      socket.on("notification", (resOn) => {
        if(resOn?.id && active) {
          playSoundRecieve()
          dispatch(onReceiveNot(resOn))
        }
      });
    }

    if (socket) {
      socket.on("user is online", (resVal) => {
        if(!location?.pathname?.includes('/chat')) {
          dispatch(onNotifStatusChange(resVal));
        } else {
          dispatch(onUserStatusChange(resVal));
        }
      });

      socket.on("user is offline", (resVal) => {
        if(!location?.pathname?.includes('/chat')) {
          dispatch(onNotifStatusChange(resVal));
        } else {
          dispatch(onUserStatusChange(resVal));
        }
      });

      socket.on("i read message",(resOn) => {
        if(location?.pathname?.includes('/chat')) {
          dispatch(markConversationAsRead(resOn?.conversationId));
        } else {
          dispatch(markNotiAsRead({id:resOn?.conversationId, type:'SMS'}));
        }
      })

    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, location]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);



  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ChartStyle />
              <Settings />
              <ScrollToTop />
              <Router />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
