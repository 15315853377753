import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box, Stack, List, Badge,
  Button, Avatar, Tooltip,
  Divider, Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// Hooks
import useAuth from '../../../hooks/useAuth';
// utils
import { fToNowShort } from '../../../utils/formatTime';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSelector, useDispatch } from '../../../redux/store';
import { resetNotifications, markNotiAsRead } from '../../../redux/slices/notification';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import TextMaxLine from '../../../components/TextMaxLine';
import { IconButtonAnimate } from '../../../components/animate';
import BadgeStatus from '../../../components/BadgeStatus';


// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socket } = useAuth();

  const { notifications } = useSelector((state) => state.notification);
  const totalUnRead = notifications?.length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    if (socket) {
      socket.emit("read notification",{})
    }
    dispatch(resetNotifications('ALL'))
  };

  const handleSelectConversation = (id, type) => {
    setOpen(null);
    if (type === 'contact_form_entry') {
      if (socket) {
        socket.emit("read notification", {
          notificationId: id,
        })
      }

      dispatch(markNotiAsRead({id, type}))
      navigate(PATH_DASHBOARD.inquiryList);
    } else {
      dispatch(resetNotifications('SMS'))
      navigate(PATH_DASHBOARD.chat.view(id));
    }
  };

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            {(totalUnRead > 0) && <Typography variant="body2" sx={{ color: 'text.secondary' }}> You have {totalUnRead} unread notifications </Typography>}
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

          <List
            disablePadding
            subheader={
              (totalUnRead > 0) ?
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>NEW</ListSubheader>
              :
              <Typography sx={{py:1, color: 'text.disabled'}} align="center" variant="body2">No notification yet!</Typography>
            }
          >
            <Scrollbar sx={{ maxHeight: { xs: 260, sm: 260 } }}>
              {notifications?.map((notification) => (
                <NotificationItem key={notification?.id} onSelectConversation={() => handleSelectConversation((notification?.type === 'contact_form_entry') ? notification?.id : notification?.senderProfile?.id, notification?.type)} notification={notification} />
              ))}
            </Scrollbar>
          </List>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ p: 1 }}>
          <Button disabled={!(totalUnRead > 0)} fullWidth onClick={handleMarkAllAsRead}>Clear All</Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object,
  onSelectConversation: PropTypes.func,
};

function NotificationItem({ notification, onSelectConversation }) {
  const { title, message, senderProfile, type } = notification;
  const isUnRead = true

  const notiIcon = (type === 'contact_form_entry') ? "/images/ic_notification_mail.svg" : senderProfile?.avatar?.path;
  const notiTitle = (type === 'contact_form_entry') ? title : `${senderProfile?.firstName} ${senderProfile?.lastName}`;
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={onSelectConversation}
    >
      <ListItemAvatar sx={{ position: 'relative' }}>
        {(type === 'contact_form_entry') ?
          <Avatar sx={{ bgcolor: 'rgb(244, 246, 248)' }}><img alt={notiTitle} src={notiIcon} /></Avatar>
        :
          <Avatar src={notiIcon} />
        }

        {(type !== 'contact_form_entry') ? <BadgeStatus status={(senderProfile?.isOnline) ? 'online' : 'invisible'} sx={{ right: 2, bottom: 2, position: 'absolute' }} /> : <></>}
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{notiTitle}</Typography>
            <Typography variant="caption" sx={{ mt: 0.5, display: 'flex', alignItems: 'center', color: 'text.disabled'}}>
              <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />{fToNowShort(notification?.createdAt || '')}
            </Typography>
          </Stack>
        }
        secondary={
          <TextMaxLine component="span" variant={'body2'} line={2} sx={{ color: 'text.secondary' }}>{message}</TextMaxLine>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------