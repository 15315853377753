import { useState } from 'react';
import { format, getTime, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'MMM dd, yyyy');
}

export function getYearMonth(date) {
  return format(new Date(date), 'MMM-yy');
}

export function fDateTime(date) {
  return format(new Date(date), 'MMM dd, yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

// Subtract X years from the current date
export function subtractYears(years, formataa) {
  let eighteenYearsAgo = new Date();
  eighteenYearsAgo = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear()-years);
  return format(new Date(eighteenYearsAgo), `${formataa}`);
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fToNowShort(date) {
  return formatDistanceToNowStrict(new Date(date), {
    addSuffix: true
  });
}

// get date part
export function fDatePart(date,type) {
  return format(new Date(date), type || 'yyyy');
}


// eslint-disable-next-line no-unused-vars
export function BirthDateData(year,month) {

  const [optionsDay, setOptionsDay] = useState(['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']);

  const cYear = new Date();
  const minAgeDate = cYear.getFullYear()-110;
  const maxAgeDate = cYear.getFullYear()-18;
  const optionsYear = [];
  for (let i = maxAgeDate; i > minAgeDate; i -= 1) {
    optionsYear.push(`${i}`);
  }

  const optionsMonth = [{id:1, key:'Jan'},{id:2, key:'Feb'},{id:3, key:'Mar'},{id:4, key:'Apr'},{id:5, key:'May'},{id:6, key:'Jun'},{id:7, key:'Jul'},{id:8, key:'Aug'},{id:9, key:'Sep'},{id:10, key:'Oct'},{id:11, key:'Nov'},{id:12, key:'Dec'}]

  return {
    optionsMonth,
    onChangeMonthYear: (year,month) => {
      const newDays = new Date(year || 2008, month || 1, 0).getDate();
      const daysArr = [];
      for (let i = 1; i <= newDays; i += 1) {
        daysArr.push(`${i}`);
      }
      setOptionsDay(daysArr);
    },
    optionsDay,
    optionsYear
  };
}

export function subtractMonths(date, months) {
  date.setMonth(date.getMonth() - months);
  return date;
}