import jwtDecode from 'jwt-decode';
//
import axiosInstance from './axios';

// config
import { JWT_RS_API } from '../config'

// ----------------------------------------------------------------------

const isValidToken = (refreshToken) => {
  if (!refreshToken) {
    return false;
  }
  const decoded = jwtDecode(refreshToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = async (token) => {
  if (token?.refreshToken) {
    localStorage.setItem('refreshToken', token?.refreshToken);
    if(JWT_RS_API.envType === 'local'){
      localStorage.setItem('accessToken', token?.accessToken);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${ token?.accessToken}`;
    } else {
      localStorage.setItem('accessToken', 'yes');
    }

    const resPers = await axiosInstance.get('/permissions');
    if (resPers && resPers?.data) { localStorage.setItem('permissions', JSON.stringify(resPers?.data?.data)) }
  } else {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('permissions');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
