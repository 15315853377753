import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  isAccess: PropTypes.any,
  children: PropTypes.node
};
export default function RoleBasedGuard({ isAccess = false, children }) {
  if (isAccess) {
    return <>{children}</>;
  }

  return (
    <Container>
      <Alert severity="error">
        <AlertTitle>Permission Denied</AlertTitle>
        You do not have permission to access this page
      </Alert>
    </Container>
  );
}
