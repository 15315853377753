import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path) {
  return path.includes('http');
}

export function getActive(path, pathname) {
  let isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;
  if(path === "/dashboard" && pathname !== '/dashboard'){
    if(pathname !== '/dashboard/') { isActiveRoot = false; }
  }
  return isActiveRoot;
  // return path ? !!matchPath({ path, end: false }, pathname) : false;
}
