import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// material
import { Box, Stack, Button, Alert, Typography, FormHelperText } from '@mui/material';
// Dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab';
// utils
import { fData } from '../../utils/formatNumber';
// Components
import { FormProvider } from '../../components/hook-form';
import Iconify from '../../components/Iconify';
// api
import { updateMedia } from '../../apis/mediaApi';


// Variables
const dialogContentStyle = { width: '800px', maxWidth: '100%', marginTop: '15px', paddingTop: '20px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' };

const MAX_FILE_SIZE = 100 * 1000 * 150; // 1 Mb
const FILE_FORMATS = ['jpg', 'jpeg', 'png', 'heic', 'avif', 'jfif'];


EditMediaFrom.propTypes = {
  open: PropTypes.bool,
  imageId: PropTypes.any,
  onMediaImageUpdated: PropTypes.func,
  onClose: PropTypes.func,
};
export default function EditMediaFrom({open, imageId, onMediaImageUpdated, onClose}){
  const goToElementTopRef = useRef(null);
  const imageFileRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const FormSchema = Yup.object().shape({
    mediaFile: Yup.mixed().required('Image is required').test('fileFormat', 'Unsupported Format', (value) => {
      let fileExt = ''
      if(value) {
        const fileName = value.name
        fileExt = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
      }
      return fileExt && FILE_FORMATS.includes(fileExt) })
    .test(
      'fileSize',
      `File must be less than or equal to ${fData(MAX_FILE_SIZE)}`,
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
  });

  const defaultValues = {
    mediaFile: ''
  }

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData()
      formData.append('file', data?.mediaFile);
      const apiRes = await updateMedia(imageId, formData);
      if(apiRes && apiRes?.success) {
        enqueueSnackbar(apiRes?.message || 'Image updated successfully');
        onMediaImageUpdated(apiRes?.data);
        reset()
        onClose()
      } else {
        enqueueSnackbar(apiRes.errorMsg || 'Something went wrong!', { variant: 'error' });
      }
    } catch (error) {
      setError('afterSubmit', { ...error, message: error?.message || 'Something went wrong!' });
    }
  };
  const handleClose = () => {
    onClose();
  }

  const handleClickImageFile = () => {
    imageFileRef.current?.click();
  };

  useEffect(() => {
    if (open) {
      const { current: errorElement } = goToElementTopRef;
      if (errorElement !== null) {
        errorElement.focus();
      }
    }
  }, [open]);


  return (
    <>
      <Dialog
        open={open}
        scroll={'paper'}
        maxWidth={'sm'}
      >
        <DialogTitle id="scroll-dialog-title">Update Media Image</DialogTitle>
        <DialogContent style={dialogContentStyle} dividers={false}>
          <DialogContentText id="scroll-dialog-description" ref={goToElementTopRef} tabIndex={-1} />
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && <Alert sx={{mb:2.5}} severity="error">{errors.afterSubmit.message}</Alert>}
            <Stack sx={{mt:1}} spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button
                  color="info"
                  variant="outlined"
                  onClick={handleClickImageFile}
                  startIcon={<Iconify icon={'ant-design:cloud-upload-outlined'} />}
                >
                  Upload New Image
                </Button>
                <input
                  ref={imageFileRef}
                  accept="image/*"
                  id="mediaFile"
                  name="mediaFile"
                  type="file"
                  onChange={(event) => {
                    setValue('mediaFile', event.target.files?.[0] || null);
                    imageFileRef.current.value = null;
                  }}
                  style={{ display: 'none' }}
                />
                <Box sx={{ pt: 0, mt:0, }}>
                  {values.mediaFile?.name && <Typography noWrap sx={{ maxWidth: 268, lineHeight: 1.2}} variant="subtitle2">{values.mediaFile.name}</Typography>}
                  {values.mediaFile?.size && (
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {`Size: ${(values && values.mediaFile) ? fData(values.mediaFile.size) : ''},
                      Type: ${(values && values.mediaFile) ? values.mediaFile.type : ''}`}
                    </Typography>
                  )}
                </Box>
              </Stack>
              <Box style={{marginTop:0}}>
                {(errors.mediaFile) ? (
                  <FormHelperText sx={{ display:'inline-block', pl:0 }} error>
                    {(errors && errors.mediaFile) ? errors.mediaFile.message : ''}
                  </FormHelperText>
                ) : ( <FormHelperText sx={{ display:'inline-block', pl:0 }}>{`Allowed *.jpg, *.png, *.heic, *.avif, *jfif (max size of ${fData(MAX_FILE_SIZE)})`}</FormHelperText>) }
              </Box>
            </Stack>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button disabled={(isSubmitting)} variant="outlined" onClick={handleClose} color="info">Close</Button>
          <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
            Update Media
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
