import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography, IconButton } from '@mui/material';
import Iconify from '../Iconify';
import Image from '../Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 140,
  height: 100,
  margin: 'auto',
  borderRadius: '8px',
  position: 'relative',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

SelectMedia.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  lableText: PropTypes.string,
  error: PropTypes.bool,
  sx: PropTypes.object,
  caption: PropTypes.node,
  onSelectImage: PropTypes.func,
  onMediaRemove: PropTypes.func,
};

export default function SelectMedia({file, lableText, error, sx, caption, onSelectImage, onMediaRemove, ...other }) {
  const {isDragActive, isDragReject } = useDropzone({
    multiple: false,
    ...other
  });
  const lableTextData = lableText || 'Upload photo';
  return (
    <>
      <RootStyle sx={sx}>
        {file && (
          <Box sx={{ top: -8, right: -8, position: 'absolute' }}>
            <IconButton
              size="small"
              onClick={() => onMediaRemove() }
              sx={{
                p: '2px',
                color: 'common.white',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                '&:hover': {
                  bgcolor: 'error.main',
                }
              }}
            >
              <Iconify icon={'eva:close-fill'} />
            </IconButton>
          </Box> )
        }

        <DropZoneStyle
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            })
          }}
        >
          {/* {file && (
            <Box
              component="img"
              alt="avatar"
              src={isString(file) ? file : file.preview}
              sx={{ zIndex: 8, objectFit: 'cover' }}
            />
          )} */}

          {file && <Image alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />}

          <PlaceholderStyle
            onClick={onSelectImage}
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 }
              })
            }}
          >
            <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{(file) ? 'Change Image' : lableTextData}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>
      {caption}
    </>
  );
}
